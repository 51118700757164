<template>
	<div class="container">
		<lightBox ref="lightBox" :actIndex=actIndex :images=images :animation="true"/>

		
		<div class="cols2_2-8">
			<div v-if="imgs">
				<img v-for="img in imgs" :src="$store.state.server + '/' + img.path" :alt="img.alt_text" class="img1"  :title="img.title"  @click="showLighBoxMulti">
			</div>
			
			
			<div> <!-- right -->
				<div class="hl1">
					<h3 class="cap" style="margin: 1.2rem 0">Teiche, Bachläufe, Brunnen - Wasser im Garten</h3>
				</div>
				<p class="cap f2" style="font-size:1rem">Wasser bereichert jeden Garten und ist überall möglich.</p>
				<p class="cap" style="margin: 1rem 0">Die ruhige Wasserfläche von <span class="hgl1">Gartenteichen</span> lädt zum Verweilen und Betrachten ein, bietet Fröschen, Molchen, Libellen und zahlreichen weiteren Tieren einen Lebensraum und läßt  den Beobachter nie Langeweile empfinden.</p>
				<p class="cap" style="margin: 1rem 0">Der winterliche Gartenteich besticht durch die Struktur der Pflanzen in der Vegetationsruhe und bietet auch in dieser Jahreszeit einen willkommenen Blickpunkt.</p>
				<p class="cap" style="margin: 1rem 0"><span class="hgl1">Bachläufe und Brunnen</span> begleiten mit ihrem Plätschern angenehm und entspannt durch den Tag und entfalten besonders im Sommer ihre kühlende Wirkung.</p>
				<p class="cap"><span class="hgl1">Wasserbecken</span> können in jeder Größe und Form gebaut werden und bieten Platz für Seerosen und Wasserpflanzen.</p>


			</div>
		</div>
		



	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	import  lightBox from "@/components/LightBox.vue";

	export default {
		name: 'GG_Mauern_Wege',
		components: {
			// dialogComp,
			lightBox
		},
		mounted(){
			this.getImgs();

		}, // end mounted 
		data: 
		function(){
			return {
				images: [],
				actIndex: null,
				imgs: null,
			}
		}, // end data
		methods: {
			getImgs(){


				let self = this;
				let url = this.$store.state.server + '/api/getImagesIndex.php';



				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { slug: 'gartengestaltung-teiche' },
				})
				.then(function (resp) {

					self.imgs = resp.data.data[0];
					// self.pk = self.pkFilter = resp.data.data[1];

				});
			},
			showLighBoxMulti(e){

				this.images = []
				let coll = document.getElementsByClassName("container")[0].getElementsByTagName("img");

				let selSrc = e.target.src;

				let i = -1;
				    for (let item of coll) {
				    i++;

            if (item.src === selSrc) {
            	this.actIndex = i;
            }
            
				    this.images.push({src: item.src, height: item.naturalHeight, width: item.naturalWidth, title: item.title, alt: item.alt});
        }
			}


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">
	.container {
		height: 100%;
	}
	.img1 {
		margin-bottom: 2rem;
				cursor: pointer;
	}



</style>
